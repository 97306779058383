.detailMenu{
    display: flex;
    /*background-color: #fbf9f9;*/
    border: 1px solid #eceaea;
    border-radius: 8px;
    padding: 0 28px;
    margin-bottom: 27px;
    justify-content: space-evenly;
}
.containerDetailMenu{
    padding: 22px;
}
.titleDetailMenu{
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 300;
}
.descDetailMenu{
    text-transform: capitalize;
}
.statusReview{
    color: #ffc107;

}
.statusReady{
    color: #28a745;
}