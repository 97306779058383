.scrollbars {
    max-height: 650px;
    min-height: 470px;
    border: solid #d3d3d3 1px;
    overflow: auto;
    border-radius: 8px;
}

.edit_box {
    position: relative;
    padding: 15px;
    width: 100%;
    margin-top: 6px;
    font-size: 12px;
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.column {
    flex: 50%;
    padding: 10px;
    margin: 0% 2% 0% 0%;
}