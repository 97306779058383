.search-box-wrap.close,.search-box-wrap{
    display: inline-block;
    position: relative;
    font-size: 20px;
    color:gray;
    width: 35px;
    height: 42px;
}

.search-box-wrap input{
    border: 0px;
    width: 100%;
    height: 100%;
    outline: none;
    font-size: 17px;
}

.search-box-wrap.search-active{
    width: 250px;
}
.search-icon, .clear-icon{
    cursor: pointer;
    position: absolute;
    right: 6px;
    top: 11px;
    font-size: 24px;
}

.search-icon{
    display: block;

}


.clear-icon{
    display: none;
}

.input-hide{
    display: none;
}

.search-box-wrap.search-active .clear-icon{
display: block;
}

.search-box-wrap.search-active .search-icon{
    display: none;
}