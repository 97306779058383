.actionsContainer{
    font-size: 1.2rem;
}

.actionsContainer .iconContainer{
    margin: 5px;
}

.validateIcon{

}

.editIcon{
    color:#194163;
}

.status-select-container{
    width: 200px;
}

.titleFilter{
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 300;
}

.spacer{
    flex:1;
}
